import React, { FC } from "react";

interface IProps {
  card: any;
  handleChoice: (card: any) => void;
  flipped: boolean;
  matched: boolean;
}

/**
 * @author
 * @function @Card
 **/

export const Card: FC<IProps> = ({ card, handleChoice, flipped, matched }) => {
  
  return (
    <div
      className={`card${flipped ? " flipped" : ""}${matched ? " matched" : ""}`}
      data-card={card.id}
    >
      <div className="card-inner">
        <div className={`front ${card.bg}`}>
          <img src={card.image} alt="" className={!card.text ? 'full' : ''} />
          {card.text && <p className={card.class} dangerouslySetInnerHTML={{__html: card.text}} />}
          <h3 className={`${card.class}`}>{card.name}</h3>
        </div>
        <div className="back" onClick={() => handleChoice(card)}>
          <img src="/images/card-back.png" alt="" />
        </div>
      </div>
    </div>
  );
};
