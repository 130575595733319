import "./game.css";
import React, { useEffect, useState } from "react";
import { COUNTDOWN, secondsToTime, data, shuffleCards } from "../../config";
import { Card } from "./card";
import { AnimatePresence, motion } from "framer-motion";
import PlayIcon from "./svg/play";
import PauseIcon from "./svg/pause";
import { Howl } from "howler";
import QrCode from "../assets/qr-code.png";
import HelpBackground from "../assets/help-bg.png";
import GameBackground from "../assets/background.jpg";
import Demo from "../assets/demo.gif";
let INTERVAL: any = null;
let HELPINTERVAL: any = null;
const Game = ({ onEnd }: { onEnd: () => void }) => {
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [cards, setCards] = useState(shuffleCards(data));
  const [firstCard, setFirstCard] = useState<any>(null);
  const [secondCard, setSecondCard] = useState<any>(null);
  const [countdown, setCountdown] = useState(COUNTDOWN);
  const [paused, setPaused] = useState(true);
  const [showHelp, setShowHelp] = useState(true);
  const [startSound] = useState(
    new Howl({ src: ["/sounds/start.mp3"], volume: 0.5 })
  );
  const [revealSound] = useState(
    new Howl({ src: ["/sounds/reveal.mp3"], volume: 0.5 })
  );
  const [winSound] = useState(
    new Howl({ src: ["/sounds/win.mp3"], volume: 0.5 })
  );
  const [loseSound] = useState(
    new Howl({ src: ["/sounds/lose.mp3"], volume: 0.5 })
  );
  const [correctSound] = useState(
    new Howl({ src: ["/sounds/correct.mp3"], volume: 0.5 })
  );
  const [wrongSound] = useState(
    new Howl({ src: ["/sounds/wrong.mp3"], volume: 0.5 })
  );
  useEffect(() => {
    INTERVAL = setInterval(() => {
      if (countdown < 0 && !gameOver) {
        setCountdown(0);
        clearInterval(INTERVAL);
      } else {
        if (!paused && countdown > 0)
          setCountdown((countdown) => countdown - 1);
      }
    }, 1000);
    return () => clearInterval(INTERVAL);
  }, [countdown, paused]);
  useEffect(() => {
    if (countdown === 0) {
      setGameOver(true);
    }
  }, [countdown]);
  useEffect(() => {
    if (gameOver && score !== data.length) {
      loseSound.play();
      clearInterval(INTERVAL);
    }
  }, [gameOver]);
  useEffect(() => {
    if (score === data.length) {
      winSound.play();
      clearInterval(INTERVAL);
      setTimeout(() => {
        setGameOver(true);
      }, 4000);
    } else {
    }
  }, [score]);
  const closeHelp = () => {
    console.log(HELPINTERVAL);
    clearTimeout(HELPINTERVAL);
    if (showHelp && paused) {
      setShowHelp(false);
      setPaused(false);
    }
    
  };
  useEffect(() => {
    if (showHelp) {
      HELPINTERVAL = setTimeout(() => {
        closeHelp();
      }, 14000);
    } else {
      startSound.play();
    }
  }, [showHelp]);
  useEffect(() => {
    if (firstCard && secondCard) {
      if (firstCard.id === secondCard.id) {
        setTimeout(() => {
          correctSound.play();
          setScore((score) => score + 1);
          setCards((prevCards) => {
            return prevCards.map((card) => {
              if (card.id === firstCard.id || card.id === secondCard.id) {
                return { ...card, matched: true };
              }
              return card;
            });
          });
          if (!paused) resetCards();
        }, 3000);
      } else {
        setTimeout(() => {
          wrongSound.play();
          if (!paused) resetCards();
        }, 600);
      }
    }
  }, [firstCard, secondCard]);
  const resetCards = () => {
    setFirstCard(null);
    setSecondCard(null);
  };

  const handleChoice = (card: any) => {
    if (gameOver || paused || card.matched || (firstCard && secondCard)) return;
    revealSound.play();
    firstCard ? setSecondCard(card) : setFirstCard(card);
  };
  return (
    <>
      <div className={`game-wrapper${gameOver ? " opacity-0" : ""}`}>
        <div className="game-info">
          <div className="timer">
            <svg viewBox="0 0 56 18">
              <text x="8" y="15">
                {secondsToTime(countdown)}
              </text>
            </svg>
          </div>
        </div>
        <div className="game-board">
          <img
            src={GameBackground}
            alt=""
            className="game-background absolute top-0 left-0 w-full h-full object-fit"
          />
          {cards.map((card, i) => (
            <Card
              key={i}
              card={card}
              flipped={firstCard === card || secondCard === card}
              matched={card.matched}
              handleChoice={handleChoice}
            />
          ))}
        </div>
        <div className="game-info">
          <div className="controls">
            <button onClick={() => setPaused(!paused)}>
              {paused ? <PlayIcon /> : <PauseIcon />}
            </button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {gameOver && (
          <motion.div
            className="game-over"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="game-over-inner">
              {score === data.length && <h2>You Did It!</h2>}
              {score !== data.length && <h2>Time's Up</h2>}
              <p className="text-white text-5xl max-w-[920px] text-center">
                Scan the QR code to learn more about
                <br />
                BAE Systems career paths.
              </p>
              <p className="text-white text-4xl max-w-[920px] text-center">
                You can also speak to our teams in Hall 1 and Hall 3 today!
              </p>
              <img src={QrCode} alt="" className="w-56" />
              <button onClick={onEnd} className="restart">
                <span>Play Again</span>
                <svg
                  id="Layer_4"
                  data-name="Layer 4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100.6 102.83"
                >
                  <g id="Lose">
                    <path
                      className="cls-4"
                      d="M5.44,66.44c-.42-1.26-1.78-1.93-3.04-1.51-1.25.43-1.92,1.79-1.5,3.05,3.48,10.27,10.11,19.08,18.73,25.27,8.39,6.03,18.67,9.58,29.76,9.58,14.14,0,26.94-5.76,36.21-15.06,9.27-9.3,15-22.16,15-36.36s-5.73-27.05-15-36.36C76.33,5.76,63.53,0,49.39,0c-10.86,0-20.95,3.41-29.25,9.22-6.29,4.4-11.54,10.18-15.33,16.9v-8.82c0-1.33-1.07-2.41-2.4-2.41s-2.4,1.08-2.4,2.41v17.49c0,1.33,1.07,2.41,2.4,2.41h17.42c1.33,0,2.4-1.08,2.4-2.41s-1.07-2.41-2.4-2.41H7.01c3.47-7.77,8.99-14.41,15.86-19.22,7.51-5.25,16.65-8.34,26.51-8.34,12.82,0,24.42,5.22,32.82,13.65,8.4,8.43,13.59,20.08,13.59,32.95s-5.19,24.52-13.59,32.95c-8.4,8.43-20,13.65-32.82,13.65-10.07,0-19.39-3.21-26.99-8.68-7.81-5.61-13.81-13.59-16.96-22.9h0Z"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showHelp && (
          <motion.div
            className="help-screen"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="help-screen-inner">
              <div className="help-screen-content">
                <img src={HelpBackground} alt="" />
                <button
                  onClick={closeHelp}
                  className="absolute -right-7 -top-7 w-9 h-9"
                >
                  <svg
                    id="Layer_2"
                    data-name="Layer 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 180.36 180.36"
                  >
                    <g id="Example">
                      <g id="Cross">
                        {/* <path
                          className="cls-5"
                          d="M90.18,180.36C40.45,180.36,0,139.91,0,90.18S40.45,0,90.18,0s90.18,40.45,90.18,90.18-40.45,90.18-90.18,90.18ZM90.18,5.59C43.54,5.59,5.59,43.54,5.59,90.18s37.95,84.59,84.59,84.59,84.59-37.95,84.59-84.59S136.82,5.59,90.18,5.59Z"
                        /> */}
                        <polygon
                          className="cls-5"
                          points="130.04 54.28 126.08 50.32 90.18 86.23 54.28 50.32 50.32 54.28 86.23 90.18 50.32 126.08 54.28 130.04 90.18 94.13 126.08 130.04 130.04 126.08 94.13 90.18 130.04 54.28"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
                <div className="help-screen-content-inner">
                  <div className="demo">
                    <img src={Demo} alt="Demo" />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

// const useCountdown = (time: number) => {
//   const [countdown, setCountdown] = useState(time);
//   useEffect(() => {
//     INTERVAL = setInterval(() => {
//       if (countdown < 0) {
//         setCountdown(0);
//         clearInterval(INTERVAL);
//       } else {
//         setCountdown((countdown) => countdown - 1);
//       }
//     }, 1000);
//     return () => clearInterval(INTERVAL);
//   }, []);
//   return countdown;
// };
export default Game;
