export const data = [
  {
    image: "/images/falcon.png",
    name: "Saudi Hawks",
    matched: false,
    text: 'The Saudi Falcon aerial display team during a flight. A new design for the display team livery was unveiled at World Defense Show 2024.',
    class: 'text-black',
    bg: 'bg-white',
  },
  {
    image: "/images/hawk.png",
    name: "Hawk",
    matched: false,
    text: 'A single Hawk Advanced Jet Trainer aircraft. In service with the Royal Saudi Air Force since 1988, this aircraft has helped to train thousands of RSAF pilots',
    class: 'text-white',
    bg: 'bg-blue-550',
  },
  {
    image: "/images/academy.png",
    name: "Training Academy",
    matched: false,
    text: 'Aircraft technicians being trained at the BAE Systems Saudi Development and Training (SDT) state-of-the-art academy in Jeddah.',
    class: 'text-white',
    bg: 'bg-blue-650',
  },
  {
    image: "/images/engineer.png",
    name: "ISE Engineer",
    matched: false,
    text: 'Each day, International Systems and Engineering (ISE) designs, builds and supports, complex classified and military systems in Saudi Arabia.',
    class: 'text-white',
    bg: 'bg-teal-350',
  },
  {
    image: "/images/airbridge.png",
    name: "Logistics Capabilities",
    matched: false,
    text: 'SMSCMC delivers world-class supply chain management and logistics for defence and civilian industries in Saudi.',
    class: 'text-blue-150',
    bg: 'bg-blue-550',
  },
  {
    image: "/images/typhoon.png",
    name: "Typhoon",
    matched: false,
    text: 'First in service with Royal Saudi Airforce in 2007, the Typhoon aircraft provides simultaneously deployable air-to-air and air-to-surface capabilities.',
    class: 'text-blue-550',
    bg: 'bg-teal-150',
  },
  {
    image: "/images/azalea.png",
    name: "Azalea",
    matched: false,
    text: 'Azalea, a Multi-sensor Low Earth Orbit satellite cluster that delivers high-quality information and intelligence in real-time from space to military customers.',
    class: 'text-white',
    bg: 'bg-green-550',
  },
  // {
  //   image: "/images/falcon.png",
  //   name: "Saudi Hawks",
  //   matched: false,
  //   text: 'The Saudi Falcon aerial display team during a flight. This year, the Saudi Hawks are celebrating 25 years since their debut display.',
  //   class: 'text-blue-1000',
  //   bg: 'bg-white',
  // },
  {
    image: "/images/vision.png",
    name: "Vision 2030",
    matched: false,
    text: 'BAE Systems and its Kingdom Partner Companies are committed to supporting the Kingdom’s aim through Vision 2030, and over 78% of our workforce are Saudi Nationals.',
    class: 'text-blue-1000',
    bg: 'bg-green-550',
  },
];


export const shuffleCards = (cards: any[]) => {
  const shuffledCards = [...(cards.map(card => ({...card, text: false}))), ...cards]
    .sort(() => Math.random() - 0.5)
    .map((card, index) => ({ ...card, id: `${card.bg}-${card.class}` }));

  return shuffledCards;
};

export const COUNTDOWN = 180;

export const secondsToTime = (secs: number) => {
  if (secs < 0) {
    return "00:00";
  }

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};
