import { useState } from "react";
import Game from "./components/game";
import { AnimatePresence, motion } from "framer-motion";
import Background from "./components/assets/background.mp4";
import Logo from "./components/assets/baes-logo.png";
function App() {
  const [isRunning, setIsRunning] = useState(false);
  
  const onGameEnd = () => {
    setIsRunning(false);
  };
  return (
    <div className="App">
      <video className="background" autoPlay muted loop>
        <source src={Background} type="video/mp4" />
      </video>
      {!isRunning && (
        <div className="start">
          <img src={Logo} alt="" className="logo" />
          <h1>Test Your Memory</h1>
          <button
            onClick={() => {
              setIsRunning(true);
            }}
            className={`start-btn ${isRunning ? "hidden" : ""}`}
          >
            <svg
              id="Layer_2"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 265.03 265.03"
            >
              <g id="Welcome">
                <g>
                  <circle
                    className="cls-2"
                    cx="132.52"
                    cy="132.52"
                    r="130.52"
                  />
                  <path
                    className="cls-1"
                    d="M91.31,130.65v-57.05c0-3.98,3.22-7.2,7.2-7.2,1.5,0,2.89.46,4.04,1.24l97.85,56.79c3.43,1.99,4.61,6.39,2.62,9.82-.67,1.15-1.61,2.05-2.69,2.66l-98.21,57c-3.43,1.99-7.83.82-9.82-2.62-.66-1.14-.97-2.38-.97-3.6h-.02v-57.05h0Z"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
      )}
      <AnimatePresence>
        {isRunning && (
          <motion.div
            className="game"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Game onEnd={onGameEnd} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;
